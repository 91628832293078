@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ojuju:wght@200..800&display=swap');
body{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400px;
  font-style: normal;
  cursor:context-menu;
}
.logotipo{
  height: 90px;
}
.h5{
  font-size: 18px;
}
.servicios2 {
  display: flex;
  justify-content: space-between;
}

.nosotros-container {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.mosaico {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
}

.fila {
  display: flex;
  gap: 10px; /* Espacio entre las imágenes */
}

.imagen-container {
  position: relative; /* Posición relativa para el contenedor de imagen */
}

.overlay {
  position: absolute; /* Posición absoluta para el overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */
  display: none; /* Inicialmente oculto */
  justify-content: center;
  align-items: center;
}

.fila-img:hover + .overlay {
  display: flex; /* Mostrar el overlay cuando se pasa el cursor sobre la imagen */
}

.overlay-text {
  color: white; /* Color del texto */
  font-size: 20px; /* Tamaño de fuente */
}

@media (max-width: 768px) {
  .fila {
    flex-direction: column; /* Cambiar la dirección de flex a columna en dispositivos móviles */
  }

  .mosaico {
    margin-left: 0; /* Eliminar el margen izquierdo en dispositivos móviles */
  }

  .texto {
    padding: 20px; /* Aumentar el espacio alrededor del texto en dispositivos móviles */
  }
}

@media (max-width: 480px) {
  .fila {
    gap: 5px; /* Reducir el espacio entre las imágenes en dispositivos móviles más pequeños */
  }

  .mosaico {
    margin-left: 0; /* Eliminar el margen izquierdo en dispositivos móviles más pequeños */
  }

  .texto {
    padding: 10px; /* Reducir el espacio alrededor del texto en dispositivos móviles más pequeños */
  }
}

.button {
  --bg: #473ce7;
  --text-color: #fff;
  position: relative;
  width: 190px;
  border: none;
  background: var(--bg);
  color: var(--text-color);
  padding: 1em;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.2s;
  border-radius: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
  box-shadow: #2b41c0 0px 7px 2px, #000 0px 8px 5px;
}

.button:hover {
  opacity: 1;
}

.button:active {
  top: 4px;
  box-shadow: #3a2bc0 0px 3px 2px,#000 0px 3px 5px;
}

.app-container {
  max-width: 800px;
  margin: auto;
  padding: 50px;
  text-align: center;
color:white;
  border-radius: 20px;

  height: 354px;
  border-radius: 1rem;
  background-color:#07182E;
  
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
h2{
  margin-left: 10px;
  color: #1a356e;
}
navbar {
  background-color: #1a356e;
  position: fixed;
  width: 100%;
  height: 80px; /* Ajusta la altura según tus necesidades */
  
  margin: 0;
  padding: 0;

  
  display: flex;
  align-items: center; /* Para centrar verticalmente el contenido */
  z-index: 1000;
}


navbar img {
  height: 60%; /* Ajusta el tamaño de la imagen según tus necesidades */
  margin-right: 10px; /* Añade espacio entre la imagen y el texto */
}
 ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
 ul li {
  display: inline-block;
  margin-right: 50px;
  font-size: 20px;
}

 ul li:last-child {
  margin-right: 0;
}
 ul li a {
  text-decoration: none;
  color: #9bbafd;
}

.navbar ul li a:hover {
  color: #fff; /* Cambia el color al pasar el cursor sobre el enlace */
}



.contact-form {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  font-family: cursive;
}

.form-label {
  font-size: 20px;
  margin-bottom: 8px;
  border-radius: 15px;
}
input{
  border-radius: 8px;
  height: 25px;
}
.form-input,
.textarea {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.textarea {
  resize: vertical;
  /* Permite ajustar la altura verticalmente */
}

.input-error {
  border-color: #ff5252;
}

.error-message {
  color: #ff5252;
  font-size: 14px;
  margin-top: -8px;
  margin-bottom: 16px;
}

.continue-application {
  --color: #fff;
  --background: #404660;
  --background-hover: #3A4059;
  --background-left: #2B3044;
  --folder: #F3E9CB;
  --folder-inner: #BEB393;
  --paper: #FFFFFF;
  --paper-lines: #BBC1E1;
  --paper-behind: #E1E6F9;
  --pencil-cap: #fff;
  --pencil-top: #275EFE;
  --pencil-middle: #fff;
  --pencil-bottom: #5C86FF;
  --shadow: rgba(13, 15, 25, .2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 69px;
  transition: background 0.3s;
  color: var(--color);
  background: var(--bg, var(--background));
  font-family: cursive;
}

.continue-application>div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application>div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application>div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application>div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application>div .folder:before,
.continue-application>div .folder:after,
.continue-application>div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application>div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application>div .folder:after,
.continue-application>div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application>div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application>div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application>div .folder .paper:before,
.continue-application>div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application>div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application>div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application>div .pencil:before,
.continue-application>div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application>div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application>div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application:before,
.continue-application:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  background: var(--color);
  transform-origin: 9px 1px;
  transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
  top: 26px;
  right: 16px;
  transition: transform 0.3s;
}

.continue-application:after {
  --r: 45deg;
}

.continue-application:hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: .15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: .15s;
  --pex: -24px;
}



.course-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: left;
}

.course-card img {
  width: 100%;
  height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}
.h5{
  margin-right: 55px;
}
.servicios {
  background-color: #ffffff;
 
}

.servicios main {
  display: flex;
  justify-content: center; /* Para centrar las tarjetas horizontalmente */
}

.servicios main section {
  display: flex; /* Para colocar las tarjetas una al lado de la otra */
}
.card-socios {
  background-color: transparent;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  max-width: 300px; /* Ajusta el ancho máximo según tu diseño */
}

.card-socios h4 {
  color: #0c1f72;
}

.card-socios p {
  color: #333;
}
.video-container iframe {
  width: 450px; /* Ajusta el ancho del iframe al 100% del contenedor */
  height: 300px; /* Ajusta la altura automáticamente para mantener la proporción */
 margin-left: 300px;
}
/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media (max-width: 768px) {
  .servicios main section {
    flex-direction: column; /* Cambia la dirección del flexbox para que las tarjetas se apilen verticalmente */
  }
}
@media (max-width: 768px) {
  .video-container iframe {
    width: 100%; /* Ajusta el ancho del iframe al 100% del contenedor */
    height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
   
  }
}

/* Estilo básico para el contenedor del botón de WhatsApp */
#whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Asegura que el botón esté en la parte superior */
}

/* Estilo para el botón de WhatsApp */
#whatsapp-button {
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none; /* Elimina el contorno predeterminado al hacer clic */
}

/* Estilo para la imagen del botón de WhatsApp */
#whatsapp-button img {
  width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
  height: auto; /* Mantiene la proporción de la imagen */
}
.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  max-width: 300px; /* Ajusta el ancho máximo según tu diseño */
}

.card h4 {
  color: #0c1f72;
}

.card p {
  color: #333;
}
.carrusel-container {
  max-width: 100%; /* Ajusta según el diseño general de tu página */
  max-height: 550px; /* Ajusta el tamaño del carrusel */
  overflow: hidden; /* Para ocultar el contenido que se desborda */
}

.carrusel {
  width: 100%; /* Para asegurar que el carrusel ocupe todo el ancho del contenedor */
  height: 100%; /* Para ajustar automáticamente la altura del carrusel */
  display: flex; /* Para alinear las imágenes horizontalmente */
}

.carrusel img {
  flex: 1; /* Para que las imágenes se ajusten automáticamente al ancho del carrusel */
  object-fit: cover; /* Para que las imágenes se ajusten dentro de su contenedor manteniendo la proporción */
  object-position: center; /* Para centrar las imágenes dentro de su contenedor */
}

.row {
  display: flex; /* Usa flexbox para alinear los contadores */
  justify-content: center; /* Centra horizontalmente los contadores */
  
}

.counter-box {
  width: 200px; /* Ancho de cada contador */
  margin-bottom: 30px;
  margin: 0 10px; /* Agrega un pequeño margen entre los contadores */
}
.counter-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #f1ffb3;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.counter-ico i {
  font-size: 36px;
  color: #007bff;
}

.counter-num {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  display: flex; /* Usa flexbox para centrar el contenido */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  padding: auto;
}

.counter-text {
  font-size: 18px;
  color: #555;
}
.p-servicios{
  margin-left:60px ;
  margin-right: 60px;
  text-align: justify;
  padding: 7px;
}
hr{
  width: 90%;
  background-color: #0045da;
}
.footer {
  background-color: #000000; /* Color de fondo del footer */
  color: #fff; /* Color del texto */
  padding: 20px; /* Espaciado interno */
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-info,
.footer-contact,
.footer-social {
  flex: 1;
  margin-right: 20px; /* Espaciado entre las columnas */
}

.footer p {
  margin: 5px 0; /* Espaciado entre los párrafos */
}

.slider-container {
  max-width: 100%;
  overflow: hidden;
}

.slider {
  display: flex;
}

.slide {
  flex: 0 0 auto;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}
.p{
  text-align: justify;
  margin-left: 60px;
  margin-right: 60px;
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
  /* Haz que el logotipo sea más pequeño */
  .logotipo {
    height: 60px;
  }

  /* Ajusta el tamaño de la fuente para los encabezados */
  .h5 {
    font-size: 16px;
  }

  /* Ajusta el margen izquierdo del mosaico */
  .mosaico {
    margin-left: 0;
  }

  /* Ajusta el margen izquierdo del contenedor de texto */
  .texto {
    padding: 20px;
  }

  /* Ajusta el margen izquierdo del contenedor de video */
  .video-container {
    margin-left: 0;
  }

  /* Estilos para la barra de navegación */
  navbar {
    height: 60px;
  }

  /* Ajustes para la sección de contacto */
  .contact-form {
    max-width: 80%;
    margin: auto;
  }
}

/* Media queries para dispositivos aún más pequeños */
@media (max-width: 480px) {
  /* Haz que el logotipo sea aún más pequeño */
  .logotipo {
    height: 50px;
  }

  /* Ajusta el tamaño de la fuente para los encabezados en dispositivos más pequeños */
  .h5 {
    font-size: 14px;
  }

  /* Reduce el espacio alrededor del texto en dispositivos más pequeños */
  .texto {
    padding: 10px;
  }

  /* Ajusta el margen izquierdo del contenedor de video en dispositivos más pequeños */
  .video-container {
    margin-left: 0;
  }

  /* Ajustes para la barra de navegación en dispositivos más pequeños */
  navbar {
    height: 50px;
   
  }
  ul li {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .servicios2 {
    flex-direction: column; /* Cambia la dirección de los elementos a columna en dispositivos móviles */
  }

  .mosaico {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
  }

  .imagen-container img {
    width: 100%; /* Asegura que la imagen se ajuste al ancho del contenedor */
    height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
    border-radius: 0; /* Elimina el radio de borde en dispositivos móviles */
    margin-bottom: 20px; /* Agrega espacio entre la imagen y el texto en dispositivos móviles */
  }

  .texto {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
    padding: 20px; /* Añade espacio alrededor del texto en dispositivos móviles */
  }

  h2 {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
    text-align: center; /* Centra el texto en dispositivos móviles */
  }
}
@media (max-width: 768px) {
  .servicios2 {
    flex-direction: column; /* Cambia la dirección de los elementos a columna en dispositivos móviles */
  }

  .mosaico {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
  }

  .fila {
    flex-direction: column; /* Cambia la dirección de los elementos a columna en dispositivos móviles */
    align-items: center; /* Centra los elementos horizontalmente en dispositivos móviles */
  }

  .imagen-container {
    margin-bottom: 20px; /* Agrega espacio entre las imágenes en dispositivos móviles */
  }

  .fila-img {
    max-width: 100%; /* Ajusta el ancho máximo de las imágenes al 100% del contenedor */
    height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
  }

  .overlay {
    display: none; /* Oculta el overlay en dispositivos móviles */
  }

  .texto {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
    padding: 20px; /* Añade espacio alrededor del texto en dispositivos móviles */
    text-align: center; /* Centra el texto en dispositivos móviles */
  }

  h2 {
    margin-left: 0; /* Elimina el margen izquierdo en dispositivos móviles */
  }
}

